interface IThemeSettings {
	darkThemeUrl: string;
	lightThemeUrl: string;
	themeElement: HTMLLinkElement;
}

export type Theme = "dark" | "light";

const storageKey = "theme";

function getElement<T extends HTMLElement>(id: string) {
	return document.getElementById(id) as T | null;
}

function getSettings(): IThemeSettings | null {
	const themeElement = getElement<HTMLLinkElement>("theme");
	const darkThemeUrl = getElement<HTMLLinkElement>("theme-dark")?.href;
	const lightThemeUrl = getElement<HTMLLinkElement>("theme-light")?.href;

	return themeElement != null && darkThemeUrl && lightThemeUrl
		? { darkThemeUrl, lightThemeUrl, themeElement }
		: null;
}

function getStoredTheme() {
	return localStorage.getItem(storageKey);
}

export function getTheme(): Theme {
	const storedTheme = getStoredTheme();
	const prefersDarkScheme = window.matchMedia("(prefers-color-scheme: dark)");
	const useDarkTheme = storedTheme === "dark" || storedTheme == null && prefersDarkScheme.matches;

	return useDarkTheme ? "dark" : "light";
}

function getThemeUrl(settings: IThemeSettings, theme: Theme) {
	return theme === "dark"
		? settings.darkThemeUrl
		: settings.lightThemeUrl;
}

function getToggles() {
	return {
		darkToggle: getElement<HTMLButtonElement>("theme-toggle-dark"),
		lightToggle: getElement<HTMLButtonElement>("theme-toggle-light")
	};
}

function toggle(element: HTMLButtonElement | null, enabled: boolean, fixed: boolean) {
	if (!element) {
		return;
	}

	const color = fixed ? "primary" : "secondary";
	const on = `btn-${color}`;
	const off = `btn-outline-${color}`;

	element.className = "";
	element.classList.add("btn");
	element.classList.add(enabled ? on : off);
}

function updateTheme(settings: IThemeSettings, theme: Theme) {
	settings.themeElement.href = getThemeUrl(settings, theme);
}

function updateToggles(theme: Theme) {
	const storedTheme = getStoredTheme();
	const { darkToggle, lightToggle } = getToggles();

	toggle(darkToggle, theme === "dark", storedTheme != null);
	toggle(lightToggle, theme === "light", storedTheme != null);
}

function selectTheme(settings: IThemeSettings, theme: Theme) {
	const storedTheme = getStoredTheme();

	if (theme === storedTheme) {
		localStorage.removeItem(storageKey);
		theme = getTheme();
	} else {
		localStorage.setItem(storageKey, theme);
	}

	updateTheme(settings, theme);
	updateToggles(theme);

	document.dispatchEvent(new CustomEvent("ThemeChanged"));
}

const settings = getSettings();

if (settings) {
	const initialTheme = getTheme();

	updateTheme(settings, initialTheme);

	window.addEventListener("DOMContentLoaded", () => {
		updateToggles(initialTheme);

		const { darkToggle, lightToggle } = getToggles();

		darkToggle?.addEventListener("click", () => selectTheme(settings, "dark"));
		lightToggle?.addEventListener("click", () => selectTheme(settings, "light"));
	});

	window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", () => {
		const theme = getTheme();

		updateTheme(settings, theme);
		updateToggles(theme);

		document.dispatchEvent(new CustomEvent("ThemeChanged"));
	});
}

export { };
