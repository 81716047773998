/* eslint-disable no-duplicate-imports */
import type { editor } from "monaco-editor";
import { KeyCode } from "monaco-editor";
import { useEffect, useRef, useState } from "react";

import { Theme, getTheme } from "Framework/Themes";

function canFormat(language?: string) {
	// eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
	switch (language) {
		case "html":
			return true;

		case "json":
			return true;

		default:
			return false;
	}
}

function isHtmlElement(node: Document | Element | null | undefined): node is HTMLElement {
	if (!node) {
		return false;
	}

	if (node === document) {
		return false;
	}

	return node.nodeType === 1;
}

function mapTheme(theme: Theme): string {
	// eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
	switch (theme) {
		case "light":
			return "vs-light";

		default:
			return "vs-dark";
	}
}

interface IMonacoBaseProps {
	copyToClipboard?: boolean;
	id: string;
	invalid?: boolean;
	language?: string;
	minimap?: boolean;
	readOnly?: boolean;
	value: string;
	wordWrap?: boolean;
	onChange?: (value: string) => void;
}

interface IMonacoEditorProps extends IMonacoBaseProps {
	className?: string;
	height?: number;
	maximized: boolean;
	setMaximized: (value: boolean) => void;
}

export function MonacoEditor({ className, copyToClipboard, height, id, invalid, language, maximized, minimap, readOnly, value, wordWrap, onChange, setMaximized }: IMonacoEditorProps) {
	const editorInstance = useRef<editor.IStandaloneCodeEditor | null>(null);
	const editorValue = useRef<string>(value);
	const externalValue = useRef<string>(value);
	const elementRef = useRef<HTMLDivElement>(null);
	const onChangeRef = useRef<((value: string) => void) | undefined>(onChange);

	externalValue.current = value;
	onChangeRef.current = onChange;

	// Main editor effect
	useEffect(() => {
		if (!elementRef.current) {
			return () => {
				/* */
			};
		}

		let instance: editor.IStandaloneCodeEditor | null = null;
		let isMaximized = maximized;

		async function renderEditor(target: HTMLElement) {
			const { editor } = await import("monaco-editor");

			instance = editor.create(target, {
				automaticLayout: true,
				fontLigatures: true,
				language,
				lineNumbers: "on",
				minimap: {
					enabled: minimap ?? true,
					renderCharacters: false,
					showSlider: "always"
				},
				mouseWheelScrollSensitivity: 5,
				readOnly: readOnly ?? false,
				renderWhitespace: "all",
				tabCompletion: "on",
				tabSize: 2,
				theme: mapTheme(getTheme()),
				value: externalValue.current,
				wordWrap: wordWrap === true ? "on" : "off"
			});

			editorInstance.current = instance;

			instance.onDidChangeModelContent(() => {
				if (!instance) {
					return;
				}

				editorValue.current = instance.getValue();

				if (editorValue.current !== externalValue.current && onChangeRef.current) {
					onChangeRef.current(editorValue.current);
				}
			});

			instance.addAction({
				id: "steamcore.maximize.enter",
				keybindings: [],
				label: "View: Maximize editor",
				run: () => {
					setMaximized(true);
					instance?.focus();
					document.body.classList.add("overflow-hidden");
					isMaximized = true;
				}
			});

			instance.addAction({
				id: "steamcore.maximize.exit",
				keybindingContext: "editorTextFocus",
				keybindings: [KeyCode.Escape],
				label: "View: Restore editor or blur focus",
				run: () => {
					if (isMaximized) {
						setMaximized(false);
						document.body.classList.remove("overflow-hidden");
						isMaximized = false;
					} else if (isHtmlElement(document.activeElement)) {
						document.activeElement.blur();
					}
				}
			});

			instance.addAction({
				id: "steamcore.maximize.toggle",
				keybindings: [KeyCode.F11],
				label: "View: Toggle maximized editor",
				run: async () => {
					if (isMaximized) {
						await instance?.getAction("steamcore.maximize.exit")?.run();
					} else {
						await instance?.getAction("steamcore.maximize.enter")?.run();
					}
				}
			});
		}

		void renderEditor(elementRef.current);

		function updateTheme() {
			instance?.updateOptions({
				theme: mapTheme(getTheme())
			});
		}

		document.addEventListener("ThemeChanged", updateTheme);

		return () => {
			document.removeEventListener("ThemeChanged", updateTheme);
			instance?.dispose();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		editorInstance.current?.updateOptions({
			minimap: {
				enabled: minimap ?? true,
				renderCharacters: false
			},
			wordWrap: wordWrap === true ? "on" : "off"
		});
	}, [editorInstance, minimap, wordWrap]);

	// Value effect
	useEffect(() => {
		if (value !== editorValue.current) {
			editorInstance.current?.setValue(value);
		}
	}, [value]);

	function formatDocument() {
		if (editorInstance.current) {
			void editorInstance.current.getAction("editor.action.formatDocument")?.run();
		}
	}

	function toggleMaximize() {
		if (editorInstance.current) {
			void editorInstance.current.getAction("steamcore.maximize.toggle")?.run();
		}
	}

	return (
		<div className="position-relative" style={{ height: maximized ? "100%" : height ?? 350 }}>
			<div className="btn-group position-absolute" style={{ right: 0, top: -35 }}>
				{canFormat(language) && readOnly !== true &&
					<button className="btn btn-sm btn-tinted btn-tinted-primary" type="button" onClick={() => { formatDocument(); }}>
						<span className="bi bi-code-square me-2"></span> Format
					</button>
				}
				{copyToClipboard === true &&
					<button className="btn btn-sm btn-tinted btn-tinted-primary" type="button" onClick={() => { void navigator.clipboard.writeText(value); }}>
						<span className="bi bi-clipboard me-2"></span> Copy
					</button>
				}
				<button className="btn btn-sm btn-tinted btn-tinted-primary" type="button" onClick={() => { toggleMaximize(); }}>
					<span className="bi bi-fullscreen me-2"></span> {maximized ? "Restore" : "Maximize"}
				</button>
			</div>
			<div
				ref={elementRef}
				id={id}
				className={"form-control rounded-0 h-100 " + (invalid === true ? "is-invalid py-0 ps-0" : "p-0") + (className ? " " + className : "")}
			>
			</div>
		</div>
	);
}

interface IMonacoInputProps extends IMonacoBaseProps {
	example?: string;
	label: string;
}

export function MonacoInput(props: IMonacoInputProps) {
	const [maximized, setMaximized] = useState<boolean>(false);

	return (
		<div className={maximized ? "d-flex flex-column p-4 bg-body maximized-editor" : "mb-4 mt-5"}>
			<label className="form-label">{props.label}</label>
			{props.example && <p className="text-muted">Example: {props.example}</p>}
			<MonacoEditor {...props} maximized={maximized} setMaximized={setMaximized} />
		</div>
	);
}
